import logo from "./assets/img/logo.svg";
import sm from "./assets/img/sm.png";
import video from "./assets/img/video.mp4";
import "./App.css";

function App() {
  return (
    <div className="App bg-[url('./assets/img/bg.png')] bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center">
      <header class="fixed z-50 top-0 left-0 right-0 marquee-container bg-gradient py-5 font-bold text-2xl">
        <div class="marquee flex justify-around">
          <span>
            Join our trading bot airdrop and earn free tokens! - trade more, get
            free!
          </span>
        </div>
        <div class="marquee marquee2 hidden justify-around md:flex">
          <span>
            Join our trading bot airdrop and earn free tokens! - trade more, get
            free!{" "}
          </span>
        </div>
      </header>
      <main className="py-16 container mx-auto px-4 lg:px-16">
        <div className="w-80 mx-auto py-10">
          <img src={logo} alt="" />
        </div>
        <div className="flex flex-col items-center gap-12 lg:flex-row">
          <div className="bg-gradient h-[620px] rounded-xl flex items-center justify-center">
            <div className="p-5 bg-black h-[calc(100%-8px)] w-[calc(100%-8px)] rounded-xl flex flex-col gap-5 justify-between lg:px-12 lg:py-14">
              <div>
                <h4 className="text-2xl text-gradient uppercase">
                  TurboBot - Airdrop campaign{" "}
                </h4>
                <p className="mt-6">
                  If you have experience the Bot and hold $TBOT, you will have a
                  chance to claim airdrop token from TurboBot as a thanks to
                  your support.
                  <br />
                  <br />
                  Conditions to receive airdrop:
                  <br />
                  - Have at least 1 trading transactions
                  <br />
                  - Wallet has a long trading history
                  <br />
                  - The quantity of airdrop tokens will be based on the
                  transaction volume of the wallet
                  <br />
                  - Share this Bot to at least 3 friends
                  <br />
                  <br />
                  The airdrop token will also be received via the Bot:
                  <br />
                  @TurboSniperBot
                </p>
              </div>
              <a
                href="https://t.me/TurboSniperBot"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary rounded-xl text-xl uppercase"
              >
                CLAIM airdrop
              </a>
            </div>
          </div>
          <div className="max-w-sm relative flex justify-center items-center">
            <img src={sm} alt="" />

            <div className="w-full h-[94%] m-auto absolute rounded-2xl">
              <video
                className="w-full h-full absolute left-0 right-0 top-0 bottom-0"
                muted
                autoPlay
                playsInline
                loop
              >
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </main>
      <footer className="py-5">
        <div className="flex justify-center gap-5">
          <a
            href="https://twitter.com/TurboBotBase"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_139_109)">
                <path
                  d="M14 27.9855C21.724 27.9855 27.9855 21.724 27.9855 14C27.9855 6.27604 21.724 0.0145264 14 0.0145264C6.27604 0.0145264 0.0145264 6.27604 0.0145264 14C0.0145264 21.724 6.27604 27.9855 14 27.9855Z"
                  fill="url(#paint0_linear_139_109)"
                />
                <path
                  d="M5.3849 5.89719L12.0698 14.8354L5.34277 22.1025H6.85688L12.7465 15.7399L17.505 22.1025H22.6572L15.596 12.6617L21.8576 5.89719H20.3435L14.9196 11.7569L10.5371 5.89719H5.3849ZM7.61146 7.01235H9.97835L20.4303 20.9874H18.0634L7.61146 7.01235Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_139_109"
                  x1="14"
                  y1="0.0145264"
                  x2="14"
                  y2="27.9855"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF12D5" />
                  <stop offset="1" stop-color="#2C15FF" />
                </linearGradient>
                <clipPath id="clip0_139_109">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="https://t.me/TurboBotBase"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_139_106)">
                <path
                  d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                  fill="url(#paint0_linear_139_106)"
                />
                <path
                  d="M5.82631 13.8787L15.2541 9.99483C16.1851 9.58999 19.341 8.29499 19.341 8.29499C19.341 8.29499 20.7981 7.72799 20.6768 9.10466C20.636 9.67166 20.3128 11.6538 19.9885 13.7982L18.977 20.1507C18.977 20.1507 18.8965 21.0817 18.2081 21.2427C17.5198 21.4037 16.387 20.6757 16.1851 20.5147C16.023 20.3933 13.1506 18.5722 12.0983 17.682C11.8148 17.4393 11.4916 16.954 12.1391 16.387C13.5963 15.0512 15.3358 13.3933 16.3881 12.341C16.8735 11.8557 17.3588 10.7228 15.3358 12.0983L9.63081 15.9425C9.63081 15.9425 8.98331 16.3473 7.76998 15.9833C6.55664 15.6193 5.14031 15.134 5.14031 15.134C5.14031 15.134 4.16964 14.5273 5.82864 13.8798L5.82631 13.8787Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_139_106"
                  x1="10.9835"
                  y1="28"
                  x2="10.9835"
                  y2="8.20642e-08"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2C15FF" />
                  <stop offset="1" stop-color="#FF12D5" />
                </linearGradient>
                <clipPath id="clip0_139_106">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <p className="mt-4 text-center">
          © 2023 turbobase.tech | contact@turbobase.tech
        </p>
      </footer>
    </div>
  );
}

export default App;
